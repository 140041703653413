<template>
  <div class="out" :class="$i18n.locale == 'en' ? 'en-style' : ''">
    <div class="header" :style="is_open ? '' : 'overflow: hidden;height: 2.3rem;'">
      <div class="back" @click="$router.back()" v-show="!showZhenbao">
        < {{ $t('main.return') }}</div>
          <img src="../assets/img/banner-AIyy.png" v-show="!showZhenbao" alt="">
          <div class="container" v-if="is_open" :class="showZhenbao ? 'active' : ''">
            <div class="cishu-title">{{ $t('main.ravtimes') }}<div class="chishu-ba">{{ consume_count }}</div>
            </div>
            <div class="goumai-logo" :style="$i18n.locale == 'en' ? 'padding:0.06rem 0.1rem' : ''" @click="zhenbao">
              {{ showZhenbao ? $t('main.purchNum') : $t('main.chooseTr') }}</div>
          </div>
      </div>
      <div class="body" v-show="!showZhenbao">
        <div class="item-box">
          <div class="aiyy-item">
            <div class="aiyy-title">
              <div class="h1-title"><img class="ailogo" src="../assets/img/aiyy-logo.png" alt="">{{ $t('main.avsi') }}</div>
              <img class="lishi-logo" @click="showRd = true" src="../assets/img/lishi-logo.png" alt="">
            </div>
            <p class="aiyy-text" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;' + audio_introduce">

            </p>
          </div>
          <div class="aiyy-item">
            <div class="aiyy-title">
              <div class="h1-title">
                <img class="ailogo" src="../assets/img/aiyy-logo.png" alt="">{{ $t('main.avse') }}
              </div>
            </div>
            <p class="aiyy-text" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;' + audio_agreement">
            </p>
          </div>
        </div>
        <div class="goods">
          <div class="good-list">
            <div class="good-item" v-for="(item, index) in taocanList" :key="index" @click="taocan(item, index)" :class="type_id == item.id ? 'active' :
              ''">
              <div class="h3-wenzi">{{ item.ar_count }} {{ $t('main.works') }}</div>
              <div class="good-price">￥{{ item.ar_money }}</div>
              <div class="danci-price" :style="$i18n.locale == 'en' ? 'font-size:0.12rem' : ''">{{ $t('main.eachonlyre') }}
                {{ Math.floor(item.ar_money / item.ar_count * 100) / 100 }} {{ $t('main.yuan') }}</div>
            </div>
          </div>
          <!-- {{$t('main.ttoavyuu')}} -->
          <!-- {{$t('main.avshbe')}} -->
          <!-- <div class="bottom-title" v-if="!consume_count && !is_open">{{$t('main.avshbe')}}</div>
        <div class="bottom-title" v-if="!consume_count && is_open">{{$t('main.ttoavyuu')}}</div> -->
        </div>
        <div class="chongzhi-box" @click="czAIyy">
          <div class="chongzhi">
            <div class="price">￥ {{ totalMoney }}</div>
            <div class="cishu">/{{ totalCount }} {{ $i18n.locale == 'en' ? 'works' : '次' }}</div>
            <div :style="$i18n.locale == 'en' ? 'text-align:center;font-size:0.18rem' : ''">{{ $t('main.dap') }}</div>
          </div>
        </div>
      </div>
      <div class="body body-bottom" :class="showZhenbao ? 'active' : ''" v-show="showZhenbao">
        <div class="item-box">
          <div class="aiyy-item">
            <div class="aiyy-title">
              <div class="h1-title"><img class="ailogo" src="../assets/img/aiyy-logo.png" alt="">{{ $t('main.ctrofvis') }}
              </div>
              <div class="tiaozhuang" v-if="artist_id && collector_id" @click="qiehuan(artistType)">{{ artistType == 1 ?
                $t('main.colltre') : $t('main.authzb') }} ></div>
            </div>
            <div class="good-box" v-show="audioGoodsList.length" v-for="item in audioGoodsList" :key="item.id">
              <img class="good-zhenbao" v-lazy="(item.pictures[0] + '?width=216')" alt="">
              <div class="good-content">
                <div class="good-title">{{ item.name }}</div>
                <div class="good-bianhao">{{ $t('main.gwtrNo') }} {{ item.serial_number }}</div>
              </div>
              <div class="checkbox" v-if="item.audio_type == 0" @click="isBoxChe(item.id)">
                <div class="gouxuan-box" v-show="!item.isCheckbox"></div>
                <img class="gouxuan-aiyy" v-show="item.isCheckbox" src="../assets/img/gouxuan-aiyy.png" alt="">
              </div>
              <div class="yikaitong" v-if="item.audio_type != 0">{{ $t('main.enabled') }}</div>
            </div>
            <div class="good-nodata" v-show="!audioGoodsList.length">{{ $t('main.notrenopen') }}</div>
          </div>
          <div class="goods-box">
            <div class="goods-left">
              <div class="checkbox" @click="allCheckBox">
                <div class="gouxuan-box" v-show="!isAllWeiShow"></div>
                <img class="gouxuan-aiyy" v-show="isAllWeiShow" src="../assets/img/gouxuan-aiyy.png" alt="">
              </div>
              <div class="check-all">{{ $t('main.selectALL') }}</div>
            </div>
            <div class="goods-right">
              <div class="wujian">{{ listLength }} {{ $t('main.selected') }} </div>
              <div class="kaiqifuwu-box" @click="qidong">{{ $t('main.openser') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="Recording" v-show="showRd">
        <div class="rd-box">
          <div class="biaoti"><img src="../assets/img/aiyy-history.png" alt=""> {{ $t('main.orderRe') }}</div>
          <div class="rd-title" v-if="!aiyyList.length">{{ $t('main.nopurre') }}</div>
          <table class="rd-table" v-if="aiyyList.length">
            <tr class="table-title">
              <td>{{ $t('main.orderNum') }}</td>
              <td>{{ $t('main.purchtime') }}</td>
              <td>{{ $t('main.Cost') }}</td>
            </tr>
            <tr v-for="(item, index) in aiyyList" :key="index">
              <td class="dingdan">{{ item.order_number }}</td>
              <td class="shijian">{{ item.created_at }}</td>
              <td class="jiage">￥{{ item.pay_amount }}</td>
            </tr>
          </table>
          <div class="wrap-sure">
            <div class="sure" @click="showRd = false">{{ $t('main.determine') }}</div>
          </div>
        </div>
      </div>
      <div class="aiyyxieyi" v-if="showXieyi">
        <div class="aiyybiaoti">
          <div class="aibaioti">{{ aititle }}</div>
          <div class="aiyywenzi" v-html="aicontent"></div>
          <div class="wrap-sure">
            <div class="sure" @click="showXieyi = false">{{ $t('main.determine') }}</div>
          </div>
        </div>
      </div>
      <privacy ref="privacy"></privacy>
    </div>
</template>

<script>
import { Toast, Swipe, SwipeItem } from "vant";
import privacy from "../components/privacy.vue"
export default {
  components:{
    privacy
  },
  data() {
    return {
      audio_introduce: "",
      audio_agreement: "",
      type_id: 0,
      taocanList: [],
      totalMoney: "",
      totalCount: "",
      consume_count: 0,
      is_open: false,
      aiyyList: [],
      aititle: "",
      showRd: false,
      aicontent: "",
      showXieyi: false,
      showZhenbao: false,
      audioGoodsList: [],
      isAllWeiShow: false,
      listLength: 0,
      artistType: 1,
      userId: 0,
      openCount: 0,
      artist_id: 0,
      collector_id: 0
    }
  },
  async mounted() {
    let token = localStorage.getItem('user_token')
    if (this.$route.query.code && !token) {
      let querys = ''
      if(this.$route.query.phone){
        let phone =this.getQueryString('phone')
        let nickName = this.getQueryString('phone_code')
        querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
      }
      await this.axios.get('/auth/nt?code=' + this.$route.query.code+querys).then((res) => {
        if(res.data.code == -1 && res.data.msg == '请填写手机号'){
            
            this.$refs.privacy.openPopup()
            return
          }
        window.localStorage.setItem('user_token', res.data.data.token)
        token = localStorage.getItem('user_token')
      }).catch(() => { })
    }
     let newdata = ''
    await this.axios.post('common/encrypt',{type:5 + (this.$i18n.locale == 'en' ? 6 : 0)}).then((res)=>{
      newdata = res.data.data.data_encrypted
    })
    this.axios.post("/agreement/detail", { data:newdata  })
      .then((res) => {
        this.aititle = res.data.data.title ? res.data.data.title : ''
        this.aicontent = res.data.data.content ? res.data.data.content : ''
      })
    if (localStorage.getItem('helpinfo')) {
      let help = JSON.parse(localStorage.getItem('helpinfo'))
      if (this.$i18n.locale == 'en') {
        let data = JSON.parse(localStorage.getItem('helpinfo'))
        Object.keys(data).forEach((item) => {
          if (item.indexOf('_intl') != -1) {
            let label = item.split('_intl')[0]
            if (data[item]) {
              data[label] = data[item]
            }
          }
        })
        help = data
      } else {
        help = JSON.parse(localStorage.getItem('helpinfo'))
      }
      let title = this.$t('main.aisa')
      this.audio_introduce = help.audio_introduce ? help.audio_introduce.replace(/(\r\n|\/n)/gm, ' <br />&nbsp;&nbsp;&nbsp;') : ""
      this.audio_agreement = help.audio_introduce ? help.audio_agreement.replace(/\{{([^}])*\}}/g, `<a class="xieyi" >${title}</a>`) : ""
      if (!help.audio_introduce || !help.audio_introduce) {
        this.axios.post("/goods/getHelpInfoConfig")
          .then((res) => {
            let title = this.$t('main.aisa')
            let list = res.data.data.list ? res.data.data.list : []
            let help = {}
            for (let i = 0; i < list.length; i++) {
              help[list[i].key] = list[i].content
              help[list[i].key+'_intl'] = list[i].content_intl
            }
            if (this.$i18n.locale == 'en') {
              let data = JSON.parse(JSON.stringify(help))
              Object.keys(help).forEach((item) => {
                if (item.indexOf('_intl') != -1) {
                  let label = item.split('_intl')[0]
                  if (data[item]) {
                    data[label] = data[item]
                  }
                }
              })
              help = data
            }
            this.audio_introduce = help.audio_introduce ? help.audio_introduce.replace(/(\r\n|\/n)/gm, ' <br />&nbsp;&nbsp;&nbsp;') : ""
            this.audio_agreement = help.audio_agreement ? help.audio_agreement.replace(/\{{([^}])*\}}/g, `<a class="xieyi" @click="xieyi">${title}</a>`) : ""
            this.$nextTick(() => {
              let axieyi = document.getElementsByClassName("xieyi")[0]
              axieyi.addEventListener('click', this.xieyi)
            })
          })
      } else {
        this.$nextTick(() => {
          let axieyi = document.getElementsByClassName("xieyi")[0]
          axieyi.addEventListener('click', this.xieyi)
        })
      }

    } else {
      this.axios.post("/goods/getHelpInfoConfig")
        .then((res) => {
          let title = this.$t('main.aisa')
          let list = res.data.data.list ? res.data.data.list : []
          let help = {}
          for (let i = 0; i < list.length; i++) {
            help[list[i].key] = list[i].content
          }
          this.audio_introduce = help.audio_introduce ? help.audio_introduce.replace(/(\r\n|\/n|\r)/gm, ' <br />&nbsp;&nbsp;&nbsp;').replace(/\s+/g, "") : ""
          this.audio_agreement = help.audio_agreement ? help.audio_agreement.replace(/\{{([^}])*\}}/g, `<a class="xieyi" @click="xieyi">${title}</a>`) : ""
          this.$nextTick(() => {
            let axieyi = document.getElementsByClassName("xieyi")[0]
            axieyi.addEventListener('click', this.xieyi)
          })
        })

    }

    this.axios.get("/audio/getAudioRuleList")
      .then((res) => {
        this.taocanList = res.data.data.list ? res.data.data.list : []
        this.totalMoney = this.taocanList[0] ? this.taocanList[0].ar_money : 0
        this.totalCount = this.taocanList[0] ? this.taocanList[0].ar_count : 0
        this.type_id = this.taocanList[0] ? this.taocanList[0].id : 0
      })
    this.axios.post("/audio/getConsumerCount")
      .then((res) => {
        this.consume_count = res.data.data.consume_count ? res.data.data.consume_count : 0
        this.is_open = res.data.data.is_open ? res.data.data.is_open : false
        // this.is_open = true
      })
    // this.userId =  localStorage.getItem("art_id");
    this.axios.post("/audio/getAudioPayList")
      .then((res) => {
        this.aiyyList = res.data.data.list ? res.data.data.list : []
      })

    this.artistType = this.$route.query.artistType ? this.$route.query.artistType : 1
    this.axios.post("/audio/getUnAudioGoodsList", { artist_type: this.artistType })
      .then((res) => {
        let list = res.data.data.list ? res.data.data.list : []
        for (let i = 0; i < list.length; i++) {
          list[i].isCheckbox = false
        }
        this.audioGoodsList = list ? list : []
      })
    token = localStorage.getItem('user_token')
    if (token) {
      this.axios.get('/auth/me').then((res) => {
        this.artist_id = res.data.data.user.artist_id
        this.collector_id = res.data.data.user.collector_id
      })
    }

  },

  methods: {
    getQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return decodeURIComponent(r[2]);
			return null;
		},
    goudong(e) {
      console.log(e)
    },
    xieyi() {
      this.showXieyi = true
    },
    async qiehuan(index) {

      this.artistType = index == 1 ? 2 : 1
      await this.axios.post("/audio/getUnAudioGoodsList", { artist_type: this.artistType })
        .then((res) => {
          let list = res.data.data.list ? res.data.data.list : []
          for (let i = 0; i < list.length; i++) {
            list[i].isCheckbox = false
          }
          this.audioGoodsList = list
        })
      let list = this.audioGoodsList.filter((item) => {
        return item.audio_type == 0
      })
      if (list.length) {
        this.isAllWeiShow = false
      } else {
        this.isAllWeiShow = true
      }

    },
    isBoxChe(id) {
      let index = this.audioGoodsList.findIndex((item) => { return item.id == id })
      this.audioGoodsList[index].isCheckbox = !this.audioGoodsList[index].isCheckbox

      let Length = this.audioGoodsList.filter((item) => {
        return item.isCheckbox && item.audio_type == 0
      })
      let AllLength = this.audioGoodsList.filter((item) => {
        return item.audio_type == 0
      })
      this.listLength = Length.length
      if (this.listLength == AllLength.length) {
        this.isAllWeiShow = true
      } else {
        this.isAllWeiShow = false
      }
    },
    taocan(item, index) {
      this.totalMoney = item.ar_money
      this.totalCount = item.ar_count
      this.type_id = item.id
    },
    czAIyy() {
      // let params = {
      //     amount:this.totalMoney
      // }
      this.axios.post('/order/payAudioLogs', { type_id: this.type_id }).then((res) => {
        if (res.data.code == 0) {
          let wxpay = res.data.data
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            appId: wxpay.appId,
            timeStamp: wxpay.timestamp,
            nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
            package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: wxpay.paySign,

          }, (res) => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              weui.alert(this.$t('main.paysucc'),
                {
                  buttons: [{
                    label: this.$t('main.confIrm'), onClick: () => {
                      this.axios.post("/audio/getConsumerCount")
                        .then((res) => {
                          this.consume_count = res.data.data.consume_count ? res.data.data.consume_count : 0
                          this.is_open = res.data.data.is_open ? res.data.data.is_open : false
                        })
                      this.axios.post("/audio/getAudioPayList")
                        .then((res) => {
                          this.aiyyList = res.data.data.list ? res.data.data.list : []
                        })
                    }
                  }]
                }
              )
            } else {
              weui.alert(this.$t('main.payfail'), { buttons: [{ label: this.$t('main.confIrm') }] })
            }
          })
        } else {
          weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
        }
      })
    },
    zhenbao() {
      this.showZhenbao = !this.showZhenbao

      let list = this.audioGoodsList.filter((item) => {
        return item.audio_type == 0
      })
      let listLength = this.audioGoodsList.filter((item) => {
        return item.isCheckbox == true
      })
      if (list.length) {
        this.isAllWeiShow = false
      } else {
        this.isAllWeiShow = true
      }
      if (listLength.length == this.audioGoodsList.length) {
        this.isAllWeiShow = true
      } else {
        this.isAllWeiShow = false
      }


    },
    allCheckBox() {
      let total = this.audioGoodsList.filter((item) => {
        return item.audio_type == 0
      })
      if (!total.length) {
        return
      }



      let list = []
      list = this.audioGoodsList.filter((item) => {
        return item.isCheckbox == true
      })
      if (list.length === this.audioGoodsList.length) {
        this.audioGoodsList.forEach((item) => {
          item.isCheckbox = false
        })
        this.isAllWeiShow = false
      } else {
        this.audioGoodsList.forEach((item) => {
          item.isCheckbox = true
        })
        this.isAllWeiShow = true
      }
      let Length = this.audioGoodsList.filter((item) => {
        return item.isCheckbox && item.audio_type == 0
      })
      this.listLength = Length.length
    },
    qidong() {
      let list = this.audioGoodsList.filter((item) => {
        return item.isCheckbox && item.audio_type == 0
      })
      let goodsIdS = []
      list.forEach((item) => {
        goodsIdS.push(item.id)
      })

      if (goodsIdS.length) {
        weui.alert(this.$t('main.ovstup'), {
          buttons: [{
            label: this.$t('main.cancel'),
            type: 'primary',
            onClick: () => {
              return
            }
          }, {
            label: this.$t('main.openai'),
            onClick: () => {
              this.axios.post("/audio/editAudioCount", { goods_ids: goodsIdS, artist_type: this.artistType })
                .then((res) => {
                  if (res.data.code == -1) {
                    setTimeout(() => {
                      weui.alert(this.$t('main.rvstai'), {
                        buttons: [{
                          label: this.$t('main.cancel'),
                          type: 'primary',
                          onClick: () => {
                            return
                          }
                        }, {
                          label: this.$t('main.purchase'),
                          onClick: () => {
                            this.showZhenbao = false
                          }
                        }]
                      })
                    }, 500)

                  } else {
                    Toast.success(this.$t('main.openaiSucc'))
                    this.axios.post("/audio/getUnAudioGoodsList", { artist_type: this.artistType })
                      .then((res) => {
                        let list = res.data.data.list
                        for (let i = 0; i < list.length; i++) {
                          list[i].isCheckbox = false
                        }
                        this.audioGoodsList = list
                      })
                    this.axios.post("/audio/getConsumerCount")
                      .then((res) => {
                        this.consume_count = res.data.data.consume_count ? res.data.data.consume_count : 0
                        this.is_open = res.data.data.is_open ? res.data.data.is_open : false
                        if (res.data.data.consume_count == 0) {
                          this.showZhenbao = false
                        }
                      })
                  }
                })
            }
          }]
        })

      } else {
        return weui.alert(this.$t('main.pcwotr'), { buttons: [{ label: this.$t('main.confIrm') }] })
      }

    }
  }
}
</script>

<style scoped>
.out {
  background: url(../assets/img/background-AIyy.png) no-repeat center;
  background-size: 100% 100%;
  min-height: 100vh;
}

.header {
  padding: 0.1rem 0.1rem 0;
  box-sizing: border-box;
}

.header .back {
  position: absolute;
  font-size: 0.16rem;
  padding: 0.03rem 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: url(../assets/img/aiyy-back.png) no-repeat center;
  background-size: 100% 100%;
}

.header img {
  width: 100%;
  margin-top: 0.1rem;
}

.container {
  background: rgba(110, 81, 139, 0.9);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.7rem;
  margin-bottom: 0.2rem;
  border-radius: 0.1rem;
  box-sizing: border-box;
  padding: 0.15rem;
  margin-top: -0.5rem;
}

.container.active {
  margin-top: 0.1rem;
  position: fixed;
  top: 0;
  width: 95%;
}

.container .cishu-title {
  color: white;
  font-size: 0.14rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .chishu-ba {
  color: white;
  font-size: 0.18rem;
}

.container .goumai-logo {
  /* background: url(../assets/img/AIyy-cishu-logo.png) no-repeat center; */
  background: linear-gradient(to right, #f7e0bb, #f1c98e);
  text-align: center;
  font-size: 0.14rem;
  padding: 0.06rem 0.18rem;
  user-select: none;
  border-radius: 0.5rem;
}

.body {
  background: #5C2D7F;
  padding: 0.2rem 0.15rem 0.5rem;
  min-height: 0.5rem;
  user-select: none;
}

.body-bottom {
  padding-bottom: 0.8rem;
}

.body-bottom.active {
  margin-top: 0.9rem;
}

.item-box .aiyy-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
}

.item-box .aiyy-title .h1-title {
  color: white;
  font-size: 0.16rem;
  line-height: 0.36rem;
}

.en-style .item-box .aiyy-title .h1-title {
  color: white;
  font-size: 0.16rem;
  line-height: 0.2rem;
  display: flex;
  align-items: center;
}

.en-style .item-box .aiyy-title .tiaozhuang {
  line-height: 0.2rem;
}

.en-style .item-box .aiyy-title {
  margin-bottom: 0.2rem;
}

.item-box .aiyy-title .tiaozhuang {
  color: white;
  font-size: 0.16rem;
  line-height: 0.35rem;
}

.item-box .aiyy-title .ailogo {
  width: 0.17rem;
  height: 0.15rem;
  margin-right: 0.1rem;
}

.item-box .aiyy-title .lishi-logo {
  width: 0.36rem;
  height: 0.36rem;
}

.item-box .aiyy-item .aiyy-text {
  color: white;
  font-size: 0.12rem;
}

::v-deep .xieyi {
  color: #0EB1EC;
  text-decoration: underline;
}

.goods {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.goods .good-list {
  overflow-x: scroll;
  box-sizing: border-box;
  display: flex;
  white-space: nowrap;
  height: 1.55rem;
}

.goods .good-list::-webkit-scrollbar {
  height: 0.1rem;
  border-radius: 0.1rem;
  background-color: transparent;
  height: 0.06rem;
}

.goods .good-list::-webkit-scrollbar-thumb {
  background: #FFF2CD;
  border-radius: 0.1rem;
}

.goods .good-item {
  background-size: 100% 100%;
  border-radius: 0.05rem;
  width: 1.25rem;
  height: 1.4rem;
  flex-shrink: 0;
  margin-right: 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.2rem 0 0.1rem;
  box-sizing: border-box;
  align-items: center;
  filter: brightness(.9);
  background: url(../assets/img/AIyy-item.png) no-repeat center;
  user-select: none;
}

.goods .good-item.active {
  filter: brightness(1);
  box-shadow: inset 0 0 0 0.04rem #faf108;
  border-radius: 0.06rem;
}

.goods .good-item .h3-wenzi {
  font-size: 0.16rem;
}

.goods .good-item .good-price {
  font-size: 0.2rem;
}

.goods .good-item .danci-price {
  width: 1.25rem;
  font-size: 0.14rem;
  color: #89836F;
  white-space: normal;
  box-sizing: border-box;
  padding: 0 0.1rem;
}

.goods .bottom-title {
  color: #FFEDD1;
  margin: 0.1rem 0;
}

.chongzhi-box {

  background: #5C2D7F;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.1rem 0.12rem;
  box-sizing: border-box;

}

.chongzhi {
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFEBBB;
  border-radius: 0.05rem;
  width: 100%;
  user-select: none;
}

.chongzhi .price {
  font-size: 0.20rem;
  font-weight: 600;
  white-space: nowrap;
}

.chongzhi .cishu {
  font-size: 0.12rem;
  margin: 0.08rem 0.16rem 0 0;
  white-space: nowrap;
}

.chongzhi>div:nth-child(3) {}

.en-style .chongzhi>div:nth-child(3) {
  font-size: 0.12rem;
}

.Recording {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Recording .rd-box {
  width: 90%;
  min-height: 1rem;
  background-color: white;
  box-sizing: border-box;
  line-height: 0.4rem;
  border-radius: 0.1rem;
  overflow: hidden;
}

.Recording .rd-box .biaoti {
  font-size: 0.13rem;
  padding: 0.1rem 0.15rem;
  background: #6B409A;
  color: #F8E4C6;
  display: flex;
  align-items: center;
}

.Recording .rd-box .biaoti img {
  width: 0.17rem;
  height: 0.16rem;
  margin-right: 0.1rem;
}

.Recording .rd-box .rd-title {
  border-top: 0.01rem solid #ccc;
  line-height: 0.5rem;
  font-size: 0.14rem;
  text-align: center;
}

.Recording .rd-box .rd-table {
  border-collapse: collapse;
  /* 取消表格边框 */
  width: 100%;
}

.wrap-sure {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0.15rem 0;
}

.sure {
  background: url(../assets/img/aiyy-sure.png) no-repeat center;
  border-radius: 0.05rem;
  width: 1.5rem;
  text-align: center;
  line-height: 0.4rem;

}

.table-title td {
  line-height: 0.35rem;
  background: #F0F0F0;
}

table {
  max-height: 3rem;
  overflow-y: auto;
}

table .dingdan {
  padding: 0.1rem 0 0.1rem 0.1rem;
}

table .shijian {
  padding: 0.1rem 0;
}

table .jiage {
  padding: 0.1rem 0.1rem 0.1rem 0;
}

table,
th,
tr,
td {
  border-bottom: 1px solid hsl(0, 0%, 87%);
  /* 表格横线 */
  text-align: left;
}

tr td {
  padding: 0 0.1rem;
  font-size: 0.11rem;
  line-height: 0.3rem;
}

.aiyyxieyi {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.aiyybiaoti {
  width: 90%;
  height: 3.5rem;
  background-color: white;
  text-align: center;
  padding: 0.1rem;
  box-sizing: border-box;
  border-radius: 0.1rem;
  overflow-y: auto;
}

.aiyybiaoti .aibaioti {
  font-size: 0.16rem;
  padding: 0.1rem;
}

.aiyybiaoti .aiyywenzi {
  font-size: 0.14rem;
  text-align: left;
}

.good-box {
  background-color: rgba(143, 108, 166, 1);
  height: 0.8rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.1rem 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.05rem;
  margin-bottom: 0.15rem;
}

.good-nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 0.3rem;
}

.good-box .good-zhenbao {
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 0.1rem;
  margin-right: 0.05rem;
}

.good-box .good-content {
  color: white;
  font-size: 0.14rem;
  width: 1.8rem;
}

.good-title {
  font-size: 0.12rem;
}

.good-bianhao {
  font-size: 0.12rem;
}

.good-box .checkbox {
  width: 0.24rem;
  height: 0.24rem;
}

.good-box .yikaitong {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.1rem;
  margin-left: 0.05rem;
  width: 0.35rem;
  text-align: center;
  white-space: nowrap;
}

.good-box .checkbox .gouxuan-box {
  width: 0.24rem;
  height: 0.24rem;
  border: 0.01rem solid rgba(255, 255, 255, 0.8);
  border-radius: 0.05rem;
  margin-left: 0.01rem;
}

.good-box .checkbox .gouxuan-aiyy {
  width: 0.24rem;
  height: 0.24rem;
  border-radius: 0.05rem;
  margin-left: 0.01rem;
}

.goods-box {

  background: #5C2D7F;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.1rem 0.12rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
  ;

}

.goods-box .goods-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.1rem;
}

.goods-box .goods-left .checkbox {
  width: 0.2rem;
  height: 0.2rem;
}

.goods-box .goods-left .check-all {
  margin-left: 0.1rem;
  font-size: 0.12rem;
}

.goods-box .goods-left .checkbox .gouxuan-box {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.03rem;
}

.goods-box .goods-left .checkbox .gouxuan-aiyy {
  width: 100%;
  height: 100%;
  border-radius: 0.03rem;
}

.goods-box .goods-right {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.goods-box .kaiqifuwu-box {
  background-color: #FFF1CC;
  padding: 0.15rem 0.3rem;
  color: black;
  border-radius: 0.05rem;
  margin: 0 0.05rem 0 0.1rem;
}

.goods-box .wujian {
  font-size: 0.12rem;
}</style>